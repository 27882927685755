<template>
  <v-container fluid fill-height>
    <v-layout align-center justify-center>
      <v-flex xs10 sm8 md6 lg5 class="mt-16">
        <v-stepper v-model="currentStep" class="elevation-0 grey lighten-4">
          <v-stepper-items class="elevation-0 grey lighten-4">
            <v-stepper-content step="1">
              <email @submit="verificationCode" ref="emailForm"></email>
            </v-stepper-content>
            <v-stepper-content step="2">
              <code-form
                v-if="email"
                ref="codeForm"
                :email="email"
                @submit="resetPassword"
              ></code-form>
            </v-stepper-content>
            <v-stepper-content step="3">
              <new-password
                v-if="email && resetToken"
                ref="codeForm"
                :email="email"
                :resetToken="resetToken"
                @submit="toLogin"
              ></new-password>
            </v-stepper-content>
          </v-stepper-items>
        </v-stepper>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import Email from "@/views/auth/password/Email";
import CodeForm from "@/views/auth/password/Code";
import NewPassword from "@/views/auth/password/NewPassword";

// const allowedSteps = [
//   "Email",
//   "Code",
//   "NewPassword",
// ];

export default {
  name: "Password",
  components: { Email, CodeForm, NewPassword },
  data() {
    return {
      email: "",
      resetToken: "",
      currentStep: 1,
    };
  },
  methods: {
    verificationCode: function () {
      this.email = this.$refs.emailForm.email;
      this.currentStep = 2;
    },
    resetPassword: function () {
      this.resetToken = this.$refs.codeForm.code;
      this.currentStep = 3;
    },
    toLogin: function () {
      this.$router.push({ name: "Login" });
    },
  },
};
</script>
