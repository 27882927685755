<template>
  <v-card class="elevation-0 grey lighten-4 mt-16">
    <p class="display-1 text--primary text-center">
      {{ $t("auth.password_recovery") }}
    </p>
    <v-form @submit.prevent="sendCode">
      <v-card-text>
        <v-text-field
          id="password"
          prepend-icon="mdi-lock"
          name="password"
          :label="attributeLabels.password"
          :error-messages="errors.password"
          v-model="password"
          type="password"
        ></v-text-field>
        <v-text-field
          id="password"
          prepend-icon="mdi-lock"
          name="password"
          :label="attributeLabels.confirmPassword"
          :error-messages="errors.confirmPassword"
          v-model="confirmPassword"
          type="password"
        ></v-text-field>
      </v-card-text>
      <v-card-actions class="d-flex justify-space-between">
        <v-btn type="submit" color="blue darken-1" text class="pa-4">
          {{ $t("auth.reset-code-btn") }}
        </v-btn>
        <router-link
          class="text-body-2 text-decoration-none"
          :to="{ name: 'Login' }"
        >
          {{ $t("auth.recall_password") }}
        </router-link>
      </v-card-actions>
    </v-form>
  </v-card>
</template>

<script>
import _ from "lodash";
import { AUTH_RESET_PASSWORD } from "@/store/actions/identity";
import validator from "@/mixin/validator";

import {
  maxLength,
  minLength,
  required,
  sameAs,
} from "vuelidate/lib/validators";

export default {
  name: "NewPassword",
  mixins: [validator],
  props: {
    email: {
      required: true,
      type: String,
    },
    resetToken: {
      required: true,
      type: String,
    },
  },
  data() {
    return {
      password: "",
      confirmPassword: "",
      attributeLabels: {
        password: this.$t("auth.password"),
        confirmPassword: this.$t("auth.confirmPassword"),
      },
    };
  },
  computed: {
    errors: function () {
      return _.merge(
        {
          password: [],
          confirmPassword: [],
        },
        this.validator.errors
      );
    },
  },
  methods: {
    sendCode: function () {
      if (this.validate()) {
        const { email, resetToken, password } = this;
        this.$store
          .dispatch(AUTH_RESET_PASSWORD, { email, resetToken, password })
          .then(
            (response) => {
              if (response.status === true) {
                this.$emit("submit");
              }
            },
            (err) => {
              console.log("Err", err);
            }
          )
          .catch(() => {});
      }
    },
  },
  validations: {
    password: {
      required,
      minLength: minLength(5),
      maxLength: maxLength(255),
      sameAsPassword: sameAs(function () {
        return this.confirmPassword;
      }),
    },
    confirmPassword: {
      required,
      minLength: minLength(5),
      maxLength: maxLength(255),
      sameAsPassword: sameAs(function () {
        return this.password;
      }),
    },
  },
};
</script>
