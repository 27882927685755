<template>
  <v-card class="elevation-0 grey lighten-4 mt-16">
    <p class="display-1 text--primary text-center">
      {{ $t("auth.password_recovery") }}
    </p>
    <v-form v-if="triesLeft > 0" @submit.prevent="sendCode">
      <v-alert text color="info">
        {{ $t("auth.verificationCodeWasSent") }}
      </v-alert>
      <v-card-text>
        <v-text-field
          name="code"
          :label="$t('auth.verificationCode')"
          v-model="code"
          :error-messages="validator.errors.code"
          type="text"
        ></v-text-field>
      </v-card-text>
      <v-card-actions class="d-flex justify-space-between">
        <v-btn type="submit" color="blue darken-1" text class="pa-4">
          {{ $t("auth.verify-code-btn") }}
        </v-btn>
        <router-link
          class="text-body-2 text-decoration-none"
          :to="{ name: 'Login' }"
        >
          {{ $t("auth.recall_password") }}
        </router-link>
      </v-card-actions>
    </v-form>
    <v-alert v-else text color="red">
      {{ $t("auth.verificationCodeError") }}
    </v-alert>
  </v-card>
</template>

<script>
import _ from "lodash";
import { AUTH_VALIDATE_RECOVERY_CODE } from "@/store/actions/identity";
import validator from "@/mixin/validator";

import {
  maxLength,
  minLength,
  required,
  numeric,
} from "vuelidate/lib/validators";

export default {
  name: "Code",
  mixins: [validator],
  props: {
    email: {
      required: true,
      type: String,
    },
  },
  data() {
    return {
      code: "",
      triesLeft: 5,
    };
  },
  methods: {
    sendCode: function () {
      if (this.validate()) {
        const { email, code } = this;
        this.$store
          .dispatch(AUTH_VALIDATE_RECOVERY_CODE, { email, resetToken: code })
          .then(
            (response) => {
              if (response.status === true) {
                this.$emit("submit");
              } else {
                this.triesLeft -= 1;
                this.validator = {
                  errors: {
                    code: this.$t("auth.wrongCode"),
                  },
                };
                this.validator.watchers["code"] = this.$watch(
                  "code",
                  function () {
                    let errors = JSON.parse(
                      JSON.stringify(this.validator.errors)
                    );
                    _.set(errors, "password", []);
                    this.validator.errors = errors;
                  }
                );
              }
            },
            (err) => {
              console.log("Err", err);
            }
          )
          .catch(() => {});
      }
    },
  },
  validations: {
    code: {
      required,
      numeric,
      minLength: minLength(5),
      maxLength: maxLength(255),
    },
  },
};
</script>
